/**
 * Menu
 *
 * Utilizzato in: Primary Menu, Mini Cart e Follow Us
 *
 * @see {@link /libraries/util.js}
 * @see {@link https://codyhouse.co/ds/components/app/menu}
 *
 * @type {Element}
 *
 * @author Daniele De Nobili
 */

(function (w, d) {
    var Menu = function (trigger) {
        this.trigger = trigger;
        this.nav = d.getElementById(this.trigger.getAttribute('aria-controls'));

        this.initMenuEvents();
    };

    Menu.prototype.initMenuEvents = function () {
        var self = this;

        this.trigger.addEventListener("click", function (event) {
            event.preventDefault();

            self.toggle(
                !Util.hasClass(self.nav, 'is-visible')
            );
        });
    };

    Menu.prototype.toggle = function (visible) {
        Util.toggleClass(this.nav, 'is-visible', visible);
        this.trigger.setAttribute('aria-expanded', visible ? 'true' : 'false');
    };

    Menu.prototype.checkMenuClick = function (target) {
        if (!this.nav.contains(target) && !this.trigger.contains(target)) {
            this.toggle(false);
        }
    };

    var menus = [];

    d.querySelectorAll('.js-menu-trigger').forEach(function (trigger) {
        menus.push(new Menu(trigger));
    });

    w.addEventListener('click', function (event) {
        menus.forEach(function (menu) {
            menu.checkMenuClick(event.target);
        });
    });
}(window, document));
