/**
 * Supports
 *
 * 01. SVG
 * 02. Object-Fit Image
 * 03. Lazy iframe
 * 04. DataLayer
 */

(function (w, d) {
    /**
     * SVG
     *
     * @see {@link libraries/svg4everybody.js}
     */
    svg4everybody();


    /**
     * Lazy iframe + Cookie Consent
     *
     * Viene utilizzato all'interno del the_content() se viene inserito un iframe da una risorsa estarna. In questa
     * maniera entra in funzione lazysizes. In Wp-Rocket "abilita per iframe e video" deve essere disabilitato.
     *
     * @see {@link libraries/util.js}
     * @see {@link libraries/lazysizes.js}
     */
    w.onCookiePolicyAccepted(function () {
        [].slice.call(d.getElementsByTagName('iframe')).forEach(function (iframe) {
            Util.addClass(iframe, 'lazyload');
        });
    });


    /**
     * DataLayer per Cookie Consent Given
     *
     * Non spostare questo frammento nella cartella root altrimenti non funziona più il tracking perché
     * il plugin "Google Tag Manager for WordPress" viene caricato dopo root.min.js
     */
    w.onCookiePolicyAccepted(function () {
        if (w.dataLayer) {
            w.dataLayer.push({
                'event': 'cookie_consent_given'
            });
        }
    });
}(window, document));
