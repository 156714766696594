////
/// ============================================================================
/// COMPONENT / Floating Action Button
/// ============================================================================
///
/// @group components
////

(function () {
    let open = false;
    let container = document.querySelector('.js-fab');
    let toggle = document.querySelector('.js-fabToggle');

    if (!container && !toggle) {
        return;
    }

    function createCookie(name, value, days) {
        let date,
            expires = '';

        if (days) {
            date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = name + '=' + value + expires + '; path=/';
    }

    function readCookie(name) {
        let
            nameEQ = name + '=',
            ca = document.cookie.split(';'),
            i,
            l = ca.length,
            c;

        for (i = 0; i < l; i++) {
            c = ca[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }

        return null;
    }

    function openNews() {
        createCookie('floating_action_button', true);
        container.classList.add('is-visible');
        toggle.classList.add('is-open');
        open = true;
    }

    function closeNews() {
        container.classList.remove('is-visible');
        toggle.classList.remove('is-open');
        open = false;
    }

    toggle.addEventListener('click', function () {
        if (open) {
            closeNews();
        } else {
            openNews();
        }
    });

    // setTimeout(function () {
    //     if (!readCookie('floating_action_button')) {
    //         openNews();
    //     }
    // }, 3000);
}());
