/**
 * Filters Archive Users
 *
 * 01. Utilities
 * 02. Horizontal Scroll Menu
 * 03. Toggle
 *
 * @see {@link /libraries/util.js}
 * @see {@link /root/font-loading.js}
 * @see {@link https://codepen.io/pranksinatra/pen/WGoXPp}
 *
 * @author Matteo Casonato & Daniele De Nobili
 */

(function (w, d) {
    /**
     * Utilities
     */
        // setup "gc" root object for all custom global variables and functions
        // Why gc? I work at Goshen College. Learn more at goshen.edu!
    var gc = {};

    // debounce
    gc.debounce = function (func, wait) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                func.apply(context, args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    // setup window resize debouncer that triggers callbacks (passing them starting & ending width)
    // w.innerWidth is cached as gc.width to prevent layout thrashing: https://gist.github.com/paulirish/5d52fb081b3570c81e3a
    gc.width = w.innerWidth;
    var callbacks = {}; // 'name' => callbackFunction

    w.addEventListener('resize', gc.debounce(function handleWindowResize() {
        var endWidth = w.innerWidth;
        for (var name in callbacks) {
            if (callbacks.hasOwnProperty(name)) {
                callbacks[name](gc.width, endWidth);
            }
        }
        gc.width = endWidth;
    }, 200));

    gc.addResizeCallback = function (name, callbackFunction) {
        if (!callbacks.hasOwnProperty(name)) {
            callbacks[name] = callbackFunction;
        }
    };

    gc.removeResizeCallback = function (name) {
        delete callbacks[name];
    };

    // make gc globally accessible!
    w.gc = gc;


    /**
     * Horizontal Scroll Menu
     *
     * Setup buttons on horizontally-scrolling nav bar
     * Active/deactivate site nav bar to toggle visibility of wrappers.
     *        When wrappers are visible, dropdowns can be seen and scrolled over, but nothing at the top of the page can be clicked
     *        When wrappers are invisible, dropdowns cannot be seen but the rest of the page can be interacted with fine
     */
        // Setup buttons for horizontal scrolling
    var component = d.getElementsByClassName('js-filters-tags')[0];

    // 'wrapper' that gets scrolled. Changes depending on screen width
    var outerWrap = d.getElementsByClassName('js-filters-tags-outer')[0]; // for <480 wide screens
    var innerWrap = d.getElementsByClassName('js-filters-tags-inner')[0]; // for 480+ wide screens

    // don't run if there's no site nav bar on this page (e.g. homepage)
    if (!outerWrap) {
        return;
    }

    // buttons for automatic scrolling
    var leftBtn = d.getElementsByClassName('js-scroll-tags-left')[0];
    var rightBtn = d.getElementsByClassName('js-scroll-tags-right')[0];

    // spacer on the right side of menu that rightBtn covers up
    var rightSpacerWidth = 28;

    // sticky left-aligned header on 480+ wide screens
    // var header = d.getElementById('nav__heading');

    // initialize buttons once font has been loaded (the size of the wrappers change) (timeout of 3s by default)
    setTimeout(init, 200);

    // Initialize component
    function init() {
        // Update component properties based on screen width
        reset(gc.width);

        // add click listeners on buttons
        leftBtn.addEventListener('click', scrollLeft);
        rightBtn.addEventListener('click', scrollRight);

        // on scroll, show/hide buttons (e.g. don't show scroll-left button when you're already on the left side)
        // re-calculate wrapper.scrollLeft every time, of course, b/c this changes with scrolling
        outerWrap.addEventListener('scroll', gc.debounce(function () {
            toggleButtons(wrapper.scrollLeft);
        }, 100));
        innerWrap.addEventListener('scroll', gc.debounce(function () {
            toggleButtons(wrapper.scrollLeft);
        }, 100));

        // reset when screen width changes
        gc.addResizeCallback('siteNavWrapper', function (startWidth, endWidth) {
            reset(endWidth);
        });
    }

    // the "wrapper" is the element with accurate scrollLeft and scrollWidth
    // at < 480px, wrapper is outerWrap
    // at >= 480px, wrapper is innerWrap
    var wrapper, limit, amount;

    // Update component properties so it adapts to screen width
    function reset(screenWidth) {
        if (screenWidth < 480) {
            // scrolling menu is fullwidth. header scrolls as well
            wrapper = outerWrap;
            // left button is up against left side of screen
            leftBtn.style.left = 0;
            // limit is the amount of pixels that the navigation can be horizontally scrolled
            // here, scroll area is entire width of screen.
            limit = wrapper.scrollWidth - screenWidth;
            // scroll by 250 each time button is pressed
            amount = 250;
        } else {
            // scrolling menu is almost fullwidth. header is fixed in place on left side
            wrapper = innerWrap;
            // left button should be to the right of the header
            // leftBtn.style.left = header.clientWidth + 'px';
            leftBtn.style.left = '0px';
            // scroll area is VISIBLE width of scrollable area (the "window" you can see)
            limit = wrapper.scrollWidth - wrapper.clientWidth;
            // scroll by 300 every time button is pressed
            amount = 300;
        }

        // show/hide spacer depending on whether scrolling is possible
        if (limit <= 0) {
            // no scrolling is possible. hide spacer
            Util.removeClass(component, 'is-scrollable');
        }
        // scrolling is possible. show spacer if hidden
        else {
            Util.addClass(component, 'is-scrollable');
            // adjust limit to take into account the spacer that was just added
            limit += rightSpacerWidth;
        }

        // calculate which buttons should be visible
        toggleButtons(wrapper.scrollLeft);
    }

    function scrollLeft() {
        scroll(-amount);
    }

    function scrollRight() {
        scroll(amount);
    }

    function scroll(amount) {
        var start = wrapper.scrollLeft;
        var end = start + amount;

        tween(start, end, 1000, easeInOutQuad);
    }

    function toggleButtons(scrollPos) {
        // console.log('toggleButtons', scrollPos, 'of', limit);

        // screen too wide for scrolling
        if (limit <= 0) {
            hide(leftBtn);
            hide(rightBtn);
        }
        // leftmost position (give 10px so it hides a bit prematurely)
        else if (scrollPos <= 10) {
            hide(leftBtn);
            show(rightBtn);
        }
        // rightmost position (compensate for rightSpacer)
        else if (scrollPos >= limit - rightSpacerWidth) {
            hide(rightBtn);
            show(leftBtn);
        }
        // anywhere in between
        else {
            show(leftBtn);
            show(rightBtn);
        }
    }

    function show(elem) {
        Util.removeClass(elem, 'is-hidden');
        // why the delay? so buttons can fade in/out (transitions defined in CSS classes)
        setTimeout(function () {
            Util.addClass(elem, 'is-visible');
        }, 100);
    }

    function hide(elem) {
        Util.removeClass(elem, 'is-visible');
        setTimeout(function () {
            Util.addClass(elem, 'is-hidden');
        }, 300);
    }

    function tween(start, end, duration, easing) {
        var delta = end - start;
        var startTime = performance.now();
        var tweenLoop = function (time) {
            var t = (!time ? 0 : time - startTime);
            var factor = easing(null, t, 0, 1, duration);
            wrapper.scrollLeft = start + delta * factor;
            if (t < duration && wrapper.scrollLeft !== end) {
                requestAnimationFrame(tweenLoop);
            }
        };
        tweenLoop();
    }

    function easeInOutQuad(x, t, b, c, d) {
        if ((t /= d / 2) < 1) return c / 2 * t * t + b;
        return -c / 2 * ((--t) * (t - 2) - 1) + b;
    }


    /**
     * Toggle
     */
    [].forEach.call(d.getElementsByClassName('js-filters-toggle'), function (el) {
        el.addEventListener('click', function () {
            var panel = d.getElementsByClassName('js-filters-secondary')[0];

            Util.toggleClass(panel, 'is-visible', !Util.hasClass(panel, 'is-visible'));
        })
    });

    // Forzo apertura filtri per breakpoint >= l
    if (w.matchMedia('(min-width: 1000px)').matches) {
        Util.addClass(d.getElementsByClassName('js-filters-secondary')[0], 'is-visible');
    }
}(window, document));

/**
 * Gestisco la select delle regioni.
 */
(function (w, d) {
    var filterRegionField = d.querySelector('.js-filter-region');
    var filterProvinceField = d.querySelector('.js-filter-province');

    if (!filterRegionField || !filterProvinceField || !w.filterProvinces) {
        return;
    }

    function buildSelect(regionId) {
        regionId = parseInt(regionId, 10);

        var html = '<option value="">Qualsiasi</option>';
        w.filterProvinces.forEach(function (province) {
            if (regionId && regionId !== parseInt(province.region, 10)) {
                return;
            }

            html += '<option value="' + province.id + '">' + province.name + '</option>';
        });

        return html;
    }

    filterRegionField.addEventListener('change', function () {
        filterProvinceField.innerHTML = buildSelect(this.value);
    });
}(window, document));
