/**
 * User
 *
 * 01. ACF
 * 02. Member Player
 */

(function (d) {
    /**
     * ACF
     *
     * @author Daniele De Nobili
     */
    (function () {
        // Gestisce la spunta 'Fino ad oggi' nei campi di tipo 'period'
        d.querySelectorAll('.js-acf-period').forEach(function (period) {
            var master = period.querySelector('.js-acf-period-master');
            var slaves = period.querySelectorAll('.js-acf-period-slave');

            function toggler() {
                slaves.forEach(function (slave) {
                    slave.style.display = master.checked ? 'none' : 'block';
                });
            }

            master.addEventListener('change', toggler);
            toggler();
        });
    }());


    /**
     * Member Player
     *
     * @see {@link plugins/amplitude.js}
     */
    (function () {
        if (!d.querySelector('.js-player')) {
            return;
        }

        var songs = [];
        d.querySelectorAll('.js-player-song').forEach(function (songElement) {
            var audioElement = songElement.querySelector('audio');

            audioElement.addEventListener('canplay', function () {
                var minutes = parseInt(Math.floor(audioElement.duration / 60), 10);
                var seconds = parseInt(audioElement.duration % 60, 10);

                songElement.querySelector('.js-player-duration').innerHTML = minutes + ":" + seconds;
            });

            songs.push({
                "name": songElement.dataset.name,
                "artist": songElement.dataset.artist,
                "url": audioElement.querySelector('source').getAttribute('src'),
            });
        });

        // User Playlist
        Amplitude.init({
            "songs": songs,
        });

        var progress = d.querySelector('.amplitude-song-played-progress');

        if (!progress) {
            return;
        }

        progress.addEventListener('click', function (e) {
            var offset = this.getBoundingClientRect();
            var x = e.pageX - offset.left;

            Amplitude.setSongPlayedPercentage((parseFloat(x) / parseFloat(this.offsetWidth)) * 100);
        });
    }());
}(document));