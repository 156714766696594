/**
 * Streaming
 *
 * Apertura finestra in Popup
 *
 * @author Daniele De Nobili
 */

(function (w, d) {
    d.querySelectorAll('.js-streaming > a').forEach(function (streaming) {
        streaming.addEventListener('click', function (event) {
            event.preventDefault();
            w.open(this.href, '', 'width=480,height=630,scrollbars=no');
        });
    });
}(window, document));
