/**
 * Share
 *
 * 01. Scroll view
 * 02. Share counter
 */

(function (w, d) {

    /**
     * Scroll view
     *
     * Visualizza i pulsanti di condivisione quando il viewport raggiunge
     * il data-offset impostato sull’elemento .js-share
     *
     * @see {@link libraries/util.js}
     * @see {@link https://codyhouse.co/ds/components/app/back-to-top}
     */
    let share = d.querySelector('.js-share');

    if (share) {
        let scrollOffset = parseInt(share.getAttribute('data-offset'), 10) || 0;
        let scrolling = false;

        function checkStickyShare() {
            let windowTop = w.scrollY || d.documentElement.scrollTop;
            Util.toggleClass(share, 'is-visible', windowTop >= scrollOffset);
            scrolling = false;
        }

        if (scrollOffset > 0) {
            w.addEventListener("scroll", function () {
                if (!scrolling) {
                    scrolling = true;

                    if (w.requestAnimationFrame) {
                        w.requestAnimationFrame(checkStickyShare);
                    } else {
                        setTimeout(checkStickyShare, 250);
                    }
                }
            });

            checkStickyShare();
        }
    }

})(window, document);
