/**
 * Nav
 *
 * @see https://github.com/NickDJM/accessible-menu/
 */

document.addEventListener("DOMContentLoaded", () => {
    const containerElement = document.querySelector(".js-nav");

    if (!containerElement) {
        return;
    }

    const controllerElement = document.querySelector(".js-hamburger");
    const menuElement = containerElement.querySelector("ul");

    // Aggiungo icona freccia
    document.querySelectorAll(".has-children > a").forEach((childrenElement) => {
        childrenElement.insertAdjacentHTML('beforeend', '<span class="c-nav_submenu js-nav-submenu"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12,17c-0.207,0-0.413-0.063-0.588-0.191l-11-8l1.177-1.617L12,14.764l10.412-7.572l1.177,1.617l-11,8 C12.413,16.937,12.207,17,12,17z"></path></svg></span>');
    });

    // Istanzio il menu
    const menu = new DisclosureMenu({
        menuElement,
        submenuItemSelector: ".has-children",
        // submenuToggleSelector: ".js-nav-submenu",
        containerElement,
        controllerElement,
        optionalKeySupport: true,
        openClass: 'is-show',
        closeClass: 'is-hide',
    });

    menuElement.querySelectorAll('a[aria-expanded]').forEach((a) => {
        a.addEventListener('accessibleMenuExpand', () => {
            let ul = a.closest('li').querySelector('ul');
            let rect = ul.getBoundingClientRect();
            let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
            let isInViewport = rect.right <= viewportWidth;

            if (!isInViewport) {
                ul.classList.add('is-rev');
            }
        })
    });
});
