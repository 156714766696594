/**
 * Facebook SDK
 *
 * @see {@link root/gdpr-banner.js}
 * @see {@link libraries/lazysizes.js}
 */

(function (w, d) {
    w.onCookiePolicyAccepted(function () {
        if (typeof fbLang !== 'undefined') {
            d.addEventListener('lazybeforeunveil', function (e) {
                var fb = e.target.getAttribute('data-lazyfb');

                if (fb) {
                    //e.target.style.backgroundColor = 'red';
                    w.fbAsyncInit = function () {
                        FB.init({
                            appId            : fbAppId,
                            autoLogAppEvents : true,
                            xfbml            : true,
                            version          : 'v6.0'
                        });

                        fbApiInit = true; // init flag
                    };
                    w.loadScript('https://connect.facebook.net/' + fbLang + '/sdk.js');
                }
            });
        }
    });
}(window, document))
;
