/**
 * Modal
 *
 * @see {@link plugins/jquery.magnific-popup.js}
 *
 * 01. Video
 * 02. Gallery
 * 03. Teacher
 */

(function ($) {
    /**
     * Video
     */
    (function () {
        $('.js-videoModal').magnificPopup({
            type: 'iframe',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false,
            iframe: {
                markup: '<div class="mfp-iframe-scaler">'+
                    '<div class="mfp-close"></div>'+
                    '<iframe class="mfp-iframe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'+
                    '</div>',
                patterns: {
                    youtube: {
                        index: 'youtube.com/',
                        id: 'v=',
                        src: '//www.youtube.com/embed/%id%?autoplay=1'
                    },
                    vimeo: {
                        index: 'vimeo.com/',
                        id: '/',
                        src: '//player.vimeo.com/video/%id%?autoplay=1'
                    }
                },
                srcAction: 'iframe_src',
            }
        });
    }());


    /**
     * Gallery
     */
    (function () {
        $('.js-gallery').each(function () {
            var gallery = $(this);

            gallery.magnificPopup({
                delegate: '.c-gallery__item:not(.-more) a',
                type: gallery.data('type') || 'image',
                tLoading: 'Loading image #%curr%...',
                mainClass: 'mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
                },
            });
        });
    }());


    /**
     * Network
     */
    (function () {
        $('.js-network-modal').magnificPopup({
            delegate: 'a',
            type: 'inline',
            fixedContentPos: false,
            fixedBgPos: true,
            overflowY: 'auto',
            closeBtnInside: false,
            preloader: false,
            midClick: true,
            removalDelay: 300,
            mainClass: 'mfp-move-from-top',
        });

        $(document).on('click', '.popup-modal-dismiss', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
        });
    }());


    /**
     * Teacher
     */
    (function () {
        $('.js-inline-modal').magnificPopup({
            type: 'inline',
            fixedContentPos: false,
            fixedBgPos: true,
            overflowY: 'auto',
            closeBtnInside: false,
            preloader: false,
            midClick: true,
            removalDelay: 300,
            mainClass: 'mfp-slide-bottom'
        });

        $(document).on('click', '.popup-modal-dismiss', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
        });
    }());
}(jQuery));