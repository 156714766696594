/**
 * WooCommerce
 *
 * 01. Price Range
 * 01. Minicart
 */

(function ($) {
    "use strict";

    /**
     * Price Range
     * Sostituisce il Price Range con il prezzo della variazione
     */
    (function () {
        $('.js-product-summary').each(function () {
            var wrapper = $(this);
            var originalPrice = wrapper.find('.js-original-price').html();

            var availabilities = wrapper.find('div.wc-availability');
            var price = wrapper.find('p.price');

            wrapper.on('change', 'input[name="variation_id"], input.variation_id', function (event) {
                var wooAvailabilities = wrapper.find('.woocommerce-variation-availability').addClass('hidden');
                var wooPrice = wrapper.find('.woocommerce-variation-price').addClass('hidden');

                if ('' !== $(event.target).val()) {
                    availabilities.html(wooAvailabilities.html());
                    price.html(wooPrice.find('> span.price').html());
                } else {
                    availabilities.html('');
                    price.html(originalPrice);
                }
            });
        });
    }());


    /**
     * Corregge un problema con le label dei radio button di WooCommerce Deposits nella scheda prodotto.
     *
     * Abbiamo inserito il blocco 'Aggiungi al carrello' in più punti della scheda prodotto per motivi di
     * visualizzazione responsive. Ma WooCommerce Deposits utilizza degli ID nei radio button (vedi il file
     * `wp-content/plugins/woocommerce-deposits/templates/deposit-form.php`) e quindi mi ritrovo ad avere
     * elementi con ID ripetuti all’interno della pagina. Ecco perché se clicco sulla label non succede nulla.
     *
     * La soluzione elaborata, consiste nel rimuovere gli ID inseriti da WooCommerce Deposits e gestire il
     * collegamento label / input semplicemente spostando l’elemento input dentro alla label.
     */
    (function () {
        $('ul.wc-deposits-option li').each(function () {
            let li = $(this);
            let input = li.find('input');
            let label = li.find('label');

            // evito di avere elementi con ID uguali all’interno della pagina
            input.removeAttr('id');
            label.removeAttr('for');

            // sposto l’elemento input dentro alla label
            input.prependTo(label);
        });
    }());


    /**
     * Minicart
     */
    (function () {
        var countWrapper = $('.js-minicart-count-wrapper');
        var totalWrapper = $('.js-minicart-total-wrapper');

        function updateMiniCart() {
            var data = {
                'action': 'load_mini_cart'
            };

            $.post(woocommerce_params.ajax_url, data, function (response) {
                $('.js-minicart').html(response.minicart);

                countWrapper.toggleClass('is-visible', response.count > 0);
                countWrapper.find('.js-minicart-count').html(response.count);

                totalWrapper.toggleClass('is-visible', response.count > 0);
                totalWrapper.find('.js-minicart-total').html(response.total);
            });
        }

        $(document.body)
            .on('updated_wc_div', updateMiniCart)
            .on('updated_cart_totals', updateMiniCart);

        updateMiniCart();
    }());

}(jQuery));
