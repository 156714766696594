/**
 * Brands
 *
 * @requires plugins/swiper.js
 */

(function (d) {

    var container = d.querySelector('.js-brands');

    if (!container) {
        return;
    }

    new Swiper(container, {
        spaceBetween: 30,
        slidesPerView: 2,
        grabCursor: true,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
        },
        pagination: {
            el: '.js-brands__pag',
            dynamicBullets: true,
            clickable: true,
        },
        breakpoints: {
            540: {
                slidesPerView: 3,
            },
            800: {
                slidesPerView: 4,
            },
            1400: {
                slidesPerView: 5,
            },
            1920: {
                slidesPerView: 6,
            }
        }
    });

}(document));
