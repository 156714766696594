/**
 * Carousel
 *
 * @requires plugins/swiper.js
 * @see https://medium.com/@networkaaron/swiper-how-to-destroy-swiper-on-min-width-breakpoints-a947491ddec8
 */

(function (d) {

    var container = d.querySelector('.js-carousel');

    if (!container) {
        return;
    }

    new Swiper(container, {
        spaceBetween: '2.43902%',
        slidesPerView: 1,
        grabCursor: true,
        watchOverflow: true,
        setWrapperSize: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
        },
        pagination: {
            el: '.js-carousel__pag',
            dynamicBullets: true,
            clickable: true,
        },
        breakpoints: {
            540: {
                slidesPerView: 2,
            },
            800: {
                slidesPerView: 3,
            },
            1400: {
                slidesPerView: 4,
            },
            1920: {
                slidesPerView: 5,
            }
        }
    });

}(document));
