/**
 * Search
 *
 * Clean URL e effetto Overlay Loney Planet
 *
 * @author Daniele De Nobili
 */

(function (w, d) {
    var searchLink = d.querySelector('.js-search-link');

    if (!searchLink) {
        return;
    }

    var searchTpl = d.getElementById('js-search-tpl').innerHTML;
    var searchForm;
    var searchOverlay;
    var searchQueryInput;
    var searchPlaceSelect;

    searchLink.addEventListener('click', function (event) {
        event.preventDefault();

        open();
    });

    function open() {
        var tempElement = d.createElement('div');

        tempElement.innerHTML = searchTpl;

        while (tempElement.firstChild) {
            d.body.appendChild(tempElement.firstChild);
        }

        searchForm = d.querySelector('.js-search-form');
        searchOverlay = d.querySelector('.js-search-overlay');
        searchQueryInput = searchForm.s;
        searchPlaceSelect = searchForm.place;

        // Autofocus on search field
        searchQueryInput.focus();

        // Redirect to the right place on form submit
        searchForm.addEventListener('submit', function (event) {
            event.preventDefault();

            redirect();
        });

        // Animation
        searchForm.classList.add('is-visible');
        searchOverlay.classList.add('u-overlay--visible');

        // Close by button
        searchForm.querySelector('.js-search-close').addEventListener('click', close);

        // Close by overlay
        searchOverlay.addEventListener('click', close);

        // Bind the search query input placeholder to the place select field
        searchPlaceSelect.addEventListener('change', function () {
            searchQueryInput.setAttribute(
                'placeholder',
                searchPlaceSelect.selectedOptions.item(0).dataset.placeholder
            );
        });
    }

    function close() {
        searchForm.classList.remove('is-visible');
        searchOverlay.classList.remove('u-overlay--visible');

        setTimeout(function () {
            searchForm.remove();
            searchOverlay.remove();
        }, 400);
    }

    function redirect() {
        var selectedPlace = searchPlaceSelect.value;
        var searchQuery = encodeURIComponent(searchQueryInput.value).replace(/%20/g, '+');
        var url = searchForm.action;

        if ('community' === selectedPlace) {
            url += 'community/?f-search=' + searchQuery;
        } else if ('radio' === selectedPlace) {
            url += 'radio/?f-search=' + searchQuery;
        } else if ('jobs' === selectedPlace) {
            url += 'jobs/?f-search=' + searchQuery;
        } else {
            // Default blog
            url += 'search/' + searchQuery + '/';
        }

        w.location = url;
    }
}(window, document));
